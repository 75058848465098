import moment, { Moment } from 'moment-timezone';
import {
  CAMPAIGN_TYPE_MENTOR,
  CAMPAIGN_TYPE_PROTEGE,
  CAMPAIGN_TYPE_STUDENTS,
  CAMPAIGN_TYPE_VOLUNTEERS,
  RoleNames,
} from '../../constants';
import { Campaign, Contact, ProgramEvent, User } from '../../models/data';

export const STUDENT_CHECKBOXES_DISMISSAL_KEY = 'STUDENT_CHECKBOXES_IS_DISMISSED';
export const TRAINING_BACKGROUND_DISMISSAL_KEY = 'TRAINING_BACKROUND_IS_DISMISSED';

export const shouldShowBackgroundCheckAlert = (contact: Contact) => {
  if (!contact || !contact.bgCheckDate) {
    return true;
  }

  const todayMoment: Moment = moment().tz('UTC').startOf('day');
  const julyFirstDayOfYear: number = todayMoment.isLeapYear() ? 183 : 182;

  let earliestValidYear: number;

  if (todayMoment.dayOfYear() < julyFirstDayOfYear) {
    // If today is before the start of the current year's fiscal year, set the earliest valid year to 3 years ago.
    earliestValidYear = todayMoment.subtract(3, 'years').year();
  } else {
    // If today is after the start of the current year's fiscal year, set the earliest valid year to 2 years ago.
    earliestValidYear = todayMoment.subtract(2, 'years').year();
  }

  // Earliest valid moment is July 1 (fiscal year beginning) of the earliest valid year
  const earliestValidMoment = moment(earliestValidYear + '-07-01', 'YYYY-MM-DD')
    .tz('UTC')
    .startOf('day');
  const bgCheckMoment = moment(contact.bgCheckDate);

  return bgCheckMoment.isBefore(earliestValidMoment);
};

export const shouldShowTrainingAlert = (contact: Contact) => {
  return !contact.trainingCompleted;
};

export const shouldShowPreAssessmentAlert = (campaign: Campaign, event?: ProgramEvent) => {
  return isItAtLeast60MinutesAfterTheEventStartTime(event, campaign.timezone);
};

export const shouldShowPostAssessmentAlert = (campaign: Campaign, event?: ProgramEvent) => {
  return isItAtLeast60MinutesAfterTheEventStartTime(event, campaign.timezone);
};

export const shouldShowProgramSurveyAlert = (campaign: Campaign) => {
  if (!campaign.fieldTripDate) {
    return false;
  }

  const timezone = campaign.timezone || 'UTC';
  const momentFieldTripDate = moment(campaign.fieldTripDate).tz(timezone);

  return momentFieldTripDate.isSame(new Date(), 'day');
};

export const shouldShowReenrollmentAlert = (currentUser: User, registrationSubmitted: boolean) => {
  return currentUser.eligibleForReenrollment && !registrationSubmitted;
};

export const shouldShowSpringEnrollmentAlert = (currentUser: User, registrationSubmitted: boolean): boolean => {
  if (registrationSubmitted) {
    return false;
  }

  return currentUser.eligibleForSpringEnrollment;

  /**
   * ALL OF THIS HAS BEEN OFFLOADED TO THE BACKEND (RTSWS-403)
   */

  // // If user was created less than 10 minutes ago, don't show alert (RTSWS-403)
  // if (currentUser.createdAt) {
  //   const tenMinutesAgo = moment().subtract(10, 'minutes');
  //   if (moment(currentUser.createdAt).isAfter(tenMinutesAgo)) {
  //     return false;
  //   }
  // }

  // // Must be a student or volunteer
  // if (!userIsStudent(currentUser) && !userIsVolunteer(currentUser)) {
  //   return false;
  // }

  // // Must not be mentor interested
  // if (userIsMentorInterested(currentUser)) {
  //   return false;
  // }

  // if (campaign) {
  //   if (userIsStudent(currentUser)) {
  //     // Student campaigns must not be protege
  //     if (campaignIsProtege(campaign)) return false;
  //   } else if (userIsVolunteer(currentUser)) {
  //     // Volunteer campaigns must not be mentor
  //     if (campaignIsMentor(campaign)) return false;
  //   }

  //   // If campaign name is present, check if today is after October of the current campaign year
  //   if (campaign.name && !todayIsAfterOctoberOfCurrentCampaignYear(campaign)) {
  //     return false;
  //   }
  // } else {
  //   // If campaign is not present, check if today is before May 1 of the current year (RTSWS-403)
  //   if (!todayIsBeforeMayOfCurrentYear(campaign)) {
  //     return false;
  //   }
  // }
};

export const shouldShowSpringEnrollmentConfirmationAlert = (currentUser: User, campaign: Campaign) => {
  // Must be a student
  if (!userIsStudent(currentUser)) {
    return false;
  }

  // Must be mentor interested
  if (!userIsMentorInterested(currentUser)) {
    return false;
  }

  // If campaign is Protege, check if today is after campaign second volunteer shift
  if (campaignIsProtege(campaign) && todayIsAfterTheCampaignSecondVolunteerShift(campaign)) {
    return false;
  }

  return true;
};

export const userIsStudent = (currentUser?: User | null) => {
  return currentUser?.role.name === RoleNames.STUDENT;
};

export const userIsVolunteer = (currentUser?: User | null) => {
  return currentUser?.role.name === RoleNames.VOLUNTEER;
};

export const userIsMentorInterested = (currentUser: User) => {
  return currentUser.contact?.mentorInterested === 'Yes';
};

export const todayIsFallEnrollmentPeriod = () => {
  return todayIsAfterMayOfCurrentYear() && !todayIsAfterOctoberOfCurrentSchoolYear();
};

export const todayIsSpringEnrollmentPeriod = () => {
  return todayIsBetweenNovemberAndApril();
};

/**
 * Is the current month one of the following months:
 * Nov, Dec, Jan, Feb, Mar, Apr, May
 */
export const todayIsAfterOctoberOfCurrentSchoolYear = () => {
  const timezone = 'UTC';
  const momentToday = moment().tz(timezone);
  const acceptableMonths = [0, 1, 2, 3, 4, 10, 11];

  return acceptableMonths.includes(momentToday.month());
};

/**
 * Is the current month one of the following months:
 * Nov, Dec, Jan, Feb, Mar, Apr
 */
export const todayIsBetweenNovemberAndApril = () => {
  const timezone = 'UTC';
  const momentToday = moment().tz(timezone);
  const acceptableMonths = [0, 1, 2, 3, 10, 11];

  return acceptableMonths.includes(momentToday.month());
};

// export const todayIsBeforeAprilOfNextYear = () => {
//   const timezone = 'UTC';
//   const currentYear = moment().tz(timezone).year();
//   const momentNextYearAprilFirst = moment(`${currentYear + 1}-04-01`).tz(timezone);
//   const momentToday = moment().tz(timezone);

//   return momentNextYearAprilFirst.isAfter(momentToday);
// };

export const todayIsBeforeAprilOfCurrentYear = () => {
  const timezone = 'UTC';
  const currentYear = moment().tz(timezone).year();
  const momentCurrentYearAprilFirst = moment(`${currentYear}-04-01`).tz(timezone);
  const momentToday = moment().tz(timezone);

  return momentCurrentYearAprilFirst.isAfter(momentToday);
};

export const todayIsAfterMayOfCurrentYear = () => {
  const timezone = 'UTC';
  const currentYear = moment().tz(timezone).year();
  const momentCurrentYearJuneFirst = moment(`${currentYear}-06-01`).tz(timezone);
  const momentToday = moment().tz(timezone);
  return momentCurrentYearJuneFirst.isSameOrBefore(momentToday);
};

const todayIsBeforeMayOfCurrentYear = (campaign: Campaign | null) => {
  const timezone = campaign && campaign.timezone ? campaign.timezone : 'UTC';
  const currentYear = moment().tz(timezone).year();
  const momentCurrentYearMayFirst = moment(`${currentYear}-05-01`).tz(timezone);
  const momentToday = moment().tz(timezone);

  return momentCurrentYearMayFirst.isAfter(momentToday);
};

// const todayIsAfterMayOfCurrentYear = (campaign: Campaign | null) => {
//   const timezone = campaign && campaign.timezone ? campaign.timezone : 'UTC';
//   const currentYear = moment().tz(timezone).year();
//   const momentCurrentYearJuneFirst = moment(`${currentYear}-06-01`).tz(timezone);
//   const momentToday = moment().tz(timezone);

export const campaignIsStudents = (campaign: Campaign) => {
  return getCampaignNameElement(campaign, 'program') === CAMPAIGN_TYPE_STUDENTS;
};

export const campaignIsVolunteers = (campaign: Campaign) => {
  return getCampaignNameElement(campaign, 'program') === CAMPAIGN_TYPE_VOLUNTEERS;
};

export const campaignIsProtege = (campaign: Campaign) => {
  return getCampaignNameElement(campaign, 'program') === CAMPAIGN_TYPE_PROTEGE;
};

export const campaignIsMentor = (campaign: Campaign) => {
  return getCampaignNameElement(campaign, 'program') === CAMPAIGN_TYPE_MENTOR;
};

export const todayIsAfterTheCampaignSecondVolunteerShift = (campaign: Campaign) => {
  //// • the current date is before the date of the current campaign's second volunteer shift date
  const timezone = campaign?.timezone || 'UTC';
  const momentToday = moment().tz(timezone);
  const unacceptableMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  return unacceptableMonths.includes(momentToday.month());
};

const getCampaignNameElement = (campaign: Campaign, element: string) => {
  const nameRegexPattern = /^(\w{4})\/(.+)\/(.+),.([SVPM]{1}\w+$)/i;
  const elements = campaign.name.match(nameRegexPattern);

  if (!elements) return '';

  switch (element) {
    case 'year':
      return elements[1] || '';
    case 'city':
      return elements[2] || '';
    case 'school':
      return elements[3] || '';
    case 'program':
      return elements[4] || '';
    default:
      break;
  }

  return '';
};

const isItAtLeast60MinutesAfterTheEventStartTime = (event?: ProgramEvent, timeZone?: string) => {
  if (!event) {
    return false;
  }

  const timezone = timeZone || 'UTC';
  const momentStartDateTime = moment(event.startDateTime).tz(timezone);
  const momentStartDateTimePlusSixtyMinutes = momentStartDateTime.clone().add(60, 'minutes');
  const momentNow = moment().tz(timezone);

  return momentStartDateTime.isSameOrBefore(momentNow) && momentStartDateTimePlusSixtyMinutes.isSameOrAfter(momentNow);
};
