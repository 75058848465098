import { Ionicons } from '@expo/vector-icons';
import { Text, VStack } from 'native-base';
import { Button } from '../../../components/ui';
import { Activity, Alert } from '../../../models/view';
import { colors } from '../../../styles';
import { generateShortUUID } from '../../../utils';

export const makeCheckInAlert = (
  currentActivity: Activity,
  handleCheckIn: () => void,
  setCheckInClicked: (value: boolean) => void,
  checkInClicked: boolean,
): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Check-in',
  message: () => {
    return checkInClicked ? (
      <VStack space={4} display="flex" flexDirection="row" alignItems="center">
        <Text>Thank you for checking in!</Text>
        <Ionicons ml={2} name="checkmark-circle-outline" color={colors.primary} size={22} />
      </VStack>
    ) : (
      <VStack space={4}>
        <Text>Are you attending {currentActivity?.title}?</Text>
        <Text>Let us know by checking in!</Text>
        <Button
          text="Check-In"
          color="secondary"
          onPress={() => {
            setCheckInClicked(true);
            handleCheckIn();
          }}
        />
      </VStack>
    );
  },
  canClose: false,
});
