import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as Clipboard from 'expo-clipboard';
import { Divider, Link, Text, VStack } from 'native-base';
import { Animated } from 'react-native';
import { Button } from '../../../components/ui';
import Curricula from '../../../constants/Curricula';
import { Campaign } from '../../../models/data';
import { Alert } from '../../../models/view';
import { RootStackParamList } from '../../../navigation';
import { colors } from '../../../styles';
import { generateShortUUID, openUrl } from '../../../utils';
import { nativeAlert } from '../../../utils/ui';
import {
  CHECKLIST_ITEM_BACKGROUND_CHECK,
  CHECKLIST_ITEM_ENROLLED_FOR_FALL,
  CHECKLIST_ITEM_ENROLLED_FOR_SPRING,
  CHECKLIST_ITEM_TRAINING,
  ChecklistItem,
} from '../useAlerts';

export const BACKGROUND_CHECK_AND_TRAINING_ALERT_TITLE = 'Background Check & Training Required';

export const makeVolunteerCheckboxesAlert = (
  handleDismissAlert: () => void,
  fadeAnim: Animated.Value,
  checklistItems: ChecklistItem[],
  campaign: Campaign | null,
  getSettingValueByName: (name: string) => string,
): Alert => {
  const animatedDismiss = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      handleDismissAlert();
    });
  };

  return {
    id: generateShortUUID(),
    type: 'warning',
    title: 'Registration Checklist',
    message: () => {
      const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
      const allItemsComplete = checklistItems.reduce((acc, item) => acc && item.status, true);

      console.log('checklistItems', checklistItems);

      return (
        <Animated.View style={{ opacity: fadeAnim }}>
          {checklistItems.map((item, index) => {
            if (item.type === CHECKLIST_ITEM_ENROLLED_FOR_FALL) {
              return renderFallEnrollmentContent(item, index);
            }
            if (item.type === CHECKLIST_ITEM_ENROLLED_FOR_SPRING) {
              return renderSpringEnrollmentContent(item, navigation, index);
            }

            if (item.type === CHECKLIST_ITEM_BACKGROUND_CHECK) {
              return (
                <VStack key={index}>
                  <VStack space={4}>
                    <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Ionicons
                        name={item.status ? 'checkbox-outline' : 'square-outline'}
                        color={colors.secondary}
                        size={22}
                      />
                      <Text fontStyle="italic" ml={2}>
                        Background Check
                      </Text>
                    </VStack>

                    {!item.status ? renderBackgroundAlertContent() : <></>}
                  </VStack>
                  {!item.status && <Divider my={2} />}
                </VStack>
              );
            }

            if (item.type === CHECKLIST_ITEM_TRAINING) {
              return (
                campaign && (
                  <VStack key={index}>
                    <VStack space={4}>
                      <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Ionicons
                          name={item.status ? 'checkbox-outline' : 'square-outline'}
                          color={colors.secondary}
                          size={22}
                        />
                        <Text fontStyle="italic" ml={2}>
                          Volunteer Training
                        </Text>
                      </VStack>
                      {!item.status ? renderTrainingAlertContent(campaign, getSettingValueByName) : <></>}
                    </VStack>
                    {!item.status && <Divider my={2} />}
                  </VStack>
                )
              );
            }
          })}

          {allItemsComplete && (
            <VStack key={generateShortUUID()}>
              <Divider my={2} />
              <VStack flex={2} my={1}>
                <Text>Great Job! You've completed the required registration, background check, and training.</Text>
              </VStack>
              <VStack mt={2}>
                <VStack flex={1}>
                  <Button text="Dismiss" color="secondary" onPress={() => animatedDismiss()} />
                </VStack>
              </VStack>
            </VStack>
          )}
        </Animated.View>
      );
    },
    canClose: true,
  };
};

const renderFallEnrollmentContent = (checklistItem: ChecklistItem, index: number) => {
  return (
    <VStack key={index}>
      <VStack space={4}>
        <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Ionicons
            name={checklistItem.status ? 'checkbox-outline' : 'square-outline'}
            color={colors.secondary}
            size={22}
          />
          <Text fontStyle="italic" ml={2}>
            Enrolled for Fall
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};

const renderSpringEnrollmentContent = (
  checklistItem: ChecklistItem,
  navigation: NativeStackNavigationProp<RootStackParamList>,
  index: number,
) => {
  return (
    <VStack key={index}>
      <VStack space={4}>
        <VStack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Ionicons
            name={checklistItem.status ? 'checkbox-outline' : 'square-outline'}
            color={colors.secondary}
            size={22}
          />
          <Text ml={2} fontStyle="italic">
            Enrolled for Spring
          </Text>
        </VStack>
      </VStack>
      {!checklistItem.status && (
        <VStack ml={8}>
          <Link w="100%" href="" onPress={() => navigation.navigate('SpringEnrollment')}>
            Enroll for Spring mentorship!
          </Link>
        </VStack>
      )}
      {!checklistItem.status && <Divider my={2} />}
    </VStack>
  );
};

const renderTrainingAlertContent = (campaign: Campaign, getSettingValueByName: (name: string) => string) => {
  // Fall back to generic training url and password if no curriculum is set
  let url = 'https://rockthestreetwallstreet.com/curriculum-training';
  let password = 'Training';

  const curriculum = Curricula.find((c) => c.name === campaign.curriculumSet);
  if (curriculum) {
    url = getSettingValueByName(curriculum.curriculum_url_slug);
    password = getSettingValueByName(curriculum.curriculum_password_slug);
  }
  return (
    <VStack>
      <Text>Volunteer Training needs to be completed.</Text>
      <Button
        text="Tap to view training curriculum"
        color="secondary"
        onPress={async () => {
          await Clipboard.setStringAsync(password);
          await nativeAlert(
            'Volunteer Training',
            `The password has been copied to the clipboard. Press "Okay" to continue.`,
          );
          openUrl(url);
          setTimeout(() => {
            Clipboard.setStringAsync('');
          }, 30000);
        }}
      />
      <Text>
        Password: <Text fontFamily="mono">{password}</Text>
      </Text>
      <Text mt={2}>After completion, this notice will be removed within 3 days.</Text>
    </VStack>
  );
};

const renderBackgroundAlertContent = () => {
  return (
    <VStack>
      <Text>
        You need a background check to complete your registration and participate with RTSWS. In the next 48 hours you
        will receive an email inviting you to complete your background check through Verified First. Please check your
        spam box if you do not receive the email.
      </Text>
      <Text>This notification will disappear when your background check is returned and approved.</Text>
    </VStack>
  );
};
