import { Button } from '../../../components/ui';
import { Alert } from '../../../models/view';
import { generateShortUUID, openUrl } from '../../../utils';

export const makePostAssessmentAlert = (postAssessmentUrl: string): Alert => ({
  id: generateShortUUID(),
  type: 'success',
  title: 'Take the Post-Assessment',
  message: () => <Button text="Open" color="secondary" onPress={() => openUrl(postAssessmentUrl)} />,
  canClose: false,
});
